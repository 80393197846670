var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "tableWrapper" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: { data: _vm.tableData },
          },
          [
            _vm._l(_vm.tableCols, function (item) {
              return _c("el-table-column", {
                key: item.prop,
                attrs: {
                  prop: item.prop,
                  label: item.label,
                  width: item.width,
                  formatter: item.formatter,
                  align: "center",
                },
              })
            }),
            _c("el-table-column", {
              attrs: {
                label: _vm.$t("list.operation"),
                align: "center",
                width: "80",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-dropdown",
                        {
                          on: {
                            command: function ($event) {
                              return _vm.handleCommand($event, scope.row)
                            },
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { type: "primary", size: "small" } },
                            [_vm._v(" 操作 ")]
                          ),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            [
                              _vm.authority.button.configure
                                ? _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "c" } },
                                    [_vm._v("配置")]
                                  )
                                : _vm._e(),
                              _vm.authority.button.edit
                                ? _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "a" } },
                                    [_vm._v("编辑")]
                                  )
                                : _vm._e(),
                              _vm.authority.button.view
                                ? _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "b" } },
                                    [_vm._v("查看")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }